import self from "../img/avatar.jpg";
import ankiMock from "../img/anki-mock.png";
import kiooskMock from "../img/kioosk-mock.png";
import mandavikMock from "../img/mandavi-mock.png";
import omarhakim from "../img/omar-hakim.png";
import overqualified from "../img/overqualified.jpg";
import outbreak from "../img/outbreak.png";
import rocker from "../img/rocker.png";
import radar from "../img/radar.png";
import outbreakPDF from "../pdf/dennis-chambers-outbreak.pdf";
import rockerPDF from "../pdf/the-rocker-drum-solo.pdf";
import radarPDF from "../pdf/radar-mash.pdf";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Sepehr",
  lastName: "Mehdipour",
  initials: "js", // the example uses first and last, but feel free to use three or more if you like.
  position: "a Front-End Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  dodgerBlue: "dodgerblue",
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "🖥️",
      text: "Front-End Developer",
    },
    {
      emoji: "🎵",
      text: "Content Publisher at Musora",
    },

    {
      emoji: "📧",
      text: "me@seppehr.com",
    },
  ],
  socials: [
    {
      link: "https://instagram.com/msmsepehr",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "#",
      icon: "fa fa-twitter",
      label: "twitter",
    },
    {
      link: "https://github.com/seppehr-com",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/seppehr/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Hey, I'm Sepehr, a music lover and software engineer. I have a passion for creating elegant and efficient software solutions that can solve complex problems. Besides my programming career, I also have a background in music and hold a degree in music and software engineering. I currently work as a music transcriber at Drumeo, where I use my expertise to create accurate and detailed transcriptions for drummers of all levels. My experience in both music and software engineering has allowed me to develop a unique skill set that helps me approach problems from different perspectives and find innovative solutions. Whether I'm writing code or transcribing a new song, my attention to detail and creativity are always at the forefront.",
  skills: {
    proficientWith: ["javascript", "react", "git"],
    exposedTo: [],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "music",
      emoji: "🎷",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "cooking",
      emoji: "🌶",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      title: "React Native Anki",
      source: "https://github.com/smdpr/react-native-anki", // this should be a link to the **repository** of the project, where the code is hosted.
      image: ankiMock,
    },
    {
      title: "Kioosk Academy",
      live: "http://kioosk.net/academy/",
      image: kiooskMock,
    },
    {
      title: "Mandavi Shop - Vatandar.org",
      live: "https://vatandar.org",
      image: mandavikMock,
    },
  ],
  transcriptions: [
    // This is where your portfolio projects will be detailed
    {
      title: "Developing Kick and Snare Syncopation by Omar Hakim",
      live: "https://www.soundslice.com/slices/5W3wc/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      download:
        "https://seppehr.com/tr/omar-hakim-developing-kick-and-snare-syncopation.pdf", // this should be a link to the **repository** of the project, where the code is hosted.
      image: omarhakim,
    },
    {
      title: "Overqualified Drummer at Kid's Concert",
      live: "https://www.soundslice.com/slices/HNykc/?from=channel", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      download: "#", // this should be a link to the **repository** of the project, where the code is hosted.
      image: overqualified,
    },
    {
      title: "Outbreak Drum Solo - Dennis Chambers",
      live: "https://www.soundslice.com/slices/Fgckc/?from=channel", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      download: "#", // this should be a link to the **repository** of the project, where the code is hosted.
      image: outbreak,
      download: outbreakPDF,
    },
    {
      title: "The Rocker - Drum Solo",
      live: "https://www.soundslice.com/slices/6n8kc/?from=channel", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      download: "#", // this should be a link to the **repository** of the project, where the code is hosted.
      image: rocker,
      download: rockerPDF,
    },
    {
      title: "Radar From MASH - Drum Solo",
      live: "https://www.soundslice.com/slices/lNhkc/?from=channel", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      download: "#", // this should be a link to the **repository** of the project, where the code is hosted.
      image: radar,
      download: radarPDF,
    },
  ],
};
