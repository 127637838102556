import React from 'react';
import IconLink from "../IconLink";
import {Box} from "@mui/material";

function TranscriptionBlock(props) {
   const {image, live, download, title} = props;
   return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
         <Box component={'img'} src={image} alt={title} style={{padding:50,}} borderRadius={'10%'} />
         <h1 style={{fontSize: '2rem'}}>{title}</h1>
         <Box className={'portfolio'} display={'flex'} flexDirection={'row'} gap={'0.5rem'}
              alignItems={'center'} fontSize={'1.5rem'} py={'2rem'}>
            {live && (
               <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                  <IconLink link={live} title={'Play'} icon={'fa fa-play'}/>
               </Box>
            )}
            {download && (
               <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                  <IconLink link={download} title={'PDF Sheet Music'} icon={'fa fa-file'}/>
               </Box>
            )}
         </Box>
      </Box>
   );
}

export default TranscriptionBlock;