import React from 'react';
import TranscriptionBlock from "./TranscriptionBlock";
import {Box, Grid} from "@mui/material";
import {info} from "../../info/Info";

export default function Transcriptions() {
    return (
        <Box>
            <Grid container display={'flex'} justifyContent={'center'}>
                {info.transcriptions.map((song, index) => (
                   <Grid item xs={12} md={6} key={index}>
                       <TranscriptionBlock image={song.image} live={song.live} download={song.download} title={song.title} />
                   </Grid>
                ))}
            </Grid>
        </Box>
    );
};